$(document).ready(function() {
  $('.tweet-wrapper').slick({
    dots: true,
    appendDots: '.dots-container',
    appendArrows: '.dots-container',
    nextArrow: '<button type="button" class="slick-next fas fa-chevron-right">Next</button>',
    prevArrow: '<button type="button" class="slick-prev fas fa-chevron-left">Previous</button>'
  });
  $('.mood-modal').removeClass('shrink');
});

$('.network-icon').click(function() {
  $(this).addClass('active');
  $(this).siblings().removeClass('active');
});

$('#sharing').change(function() {
  if ($(this).is(':checked')) {
    $('.icon-check').removeClass('far fa-square');
    $('.icon-check').addClass('fas fa-check-square');
  } else {
    $('.icon-check').removeClass('fas fa-check-square');
    $('.icon-check').addClass('far fa-square');
  }
});
$('.modal-open').click(function() {
  var modal = $(this).parent();
  modal.addClass('active');
  modal.siblings().removeClass('active');
});

$(document).mouseup(function(e) {
  var modal = $(".mood-modal.active");

  if (!e.target.matches('.mood-modal.active, .mood-modal.active *')) {
    $('.mood-modal.active').removeClass('active');
  }
});

$('.close').click(function() {
  var modal = $(this).closest('.mood-modal');
  modal.removeClass('active');
});

$('.prev').click(function() {
  var modal = $(this).closest('.mood-modal');
  modal.removeClass('active');

  var prev = modal.prev()
  if (modal.is('.modal1')) {
    prev = $('.modal10');
  } 
  while (prev.css('display') == 'none') {
    if (prev.is('.mood-modal:first-of-type')) {
      prev = $('.modal10');
    } else {
      prev = prev.prev();
    }
  }
  prev.addClass('active');
});

$('.next').click(function() {
  var modal = $(this).closest('.mood-modal');
  modal.removeClass('active');

  var next = modal.next()
  if (modal.is(':last-child')) {
    next = $('.modal1');
  } else {
    while (next.css('display') == 'none') {
      if (next.is(':last-child')) {
        next = $('.modal1');
      } else {
        next = next.next();
      }
    }
  }
  next.addClass('active');
});

$('.hamburger').click(function() {
  $('.menu').toggleClass('open');
  $(this).toggleClass('is-active');
});

$('.share-img-block').mouseenter(function() {
  $(this).addClass('hover');
  $(this).siblings().removeClass('hover');
  $(this).find('.share-block').addClass('active');
  $(this).siblings().find('.share-block').removeClass('active');
});


$('.rotary-icon').click(function() {
  $(this).addClass('active');
  $(this).siblings().removeClass('active');
  var index = $(this).index();

  var info =  $('.info-block').eq(index);
  $('.info-block').removeClass('active');
  info.addClass('active');
});

$('#rot-next').click(function() {
  var icon = $('.rotary-icon.active');
  var info = $('.info-block.active');

  icon.removeClass('active');
  if(icon.is(':last-of-type')) {
    $('.rotary-icon').first().addClass('active');
  } else {
    icon.next().addClass('active');
  }

  info.removeClass('active');
  if(info.is(':last-of-type')) {
    $('.info-block').first().addClass('active');
  } else {
    info.next().addClass('active');
  }

});

$('#rot-prev').click(function() {
  var icon = $('.rotary-icon.active');
  var info = $('.info-block.active');

  icon.removeClass('active');
  if(icon.is(':first-of-type')) {
    $('.rotary-icon').last().addClass('active');
  } else {
    icon.prev().addClass('active');
  }

  info.removeClass('active');
  if(info.is(':first-of-type')) {
    $('.info-block').last().addClass('active');
  } else {
    info.prev().addClass('active');
  }

});

$('.content-block').click(function() {
  $(this).addClass('active');
  $(this).siblings().removeClass('active');
  var index = $(this).index();

  var section = $('.content').children().eq(index);

  section.addClass('active');
  section.siblings().removeClass('active');
});

if($('.design-block').length) {
  var cropper = {
    profile: {
      handler: new Cropper($('[data-canvas-img="profile"]')[0], {
        aspectRatio: 1 / 1,
        viewMode: 1,
        scalable: false,
        rotatable: false,
        cropBoxResizable: false,
        setDragMode: 'none',
        crop: function(event) {
          console.log(event.detail.x);
          console.log(event.detail.y);
          console.log(event.detail.width);
          console.log(event.detail.height);
        }
      }),
      maxWidth: 720,
      maxHeight: 720
    }
  };
}

$('[data-canvas-selector]').on('click', function() {
  var key = $(this).data('canvasSelector');
  var $headerFrame = $('.header-frame');

  switch(key) {
    case "fb":
      $('.header-frame.fb').siblings('.header-frame.tw').removeClass('active');
      $('.header-frame.fb').addClass('active');
      break;

    case "tw":
      $('.header-frame.tw').siblings('.header-frame.fb').removeClass('active');
      $('.header-frame.tw').addClass('active');
      break;

    case "pin":
      $('.header-frame').removeClass('active');
      break;

        case "ig":
      $('.header-frame').removeClass('active');
      break;
  }
});

$('[data-canvas-button="add"]').on('click', function(e) {
  e.preventDefault();
  $(this).prev('input').trigger('click'); 
});

$('[data-canvas-button="crop"]').on('click', function(e) {
  var $img = $(this).parent().parent().find('[data-canvas-img]');
  var $croppedImg = $(this).parent().parent().find('[data-canvas-img-cropped]');
  var $downloadBtn = $(this).parents('.canvas-column').next().find('[data-canvas-button="download"]');

  $(this).addClass('hidden'); 
  $(this).next().removeClass('hidden'); 
  $img.next('.cropper-container').addClass('hidden'); 
  $img.parent().addClass('is-cropped'); 
  $downloadBtn.removeClass('hidden'); 

  var key = $img.data('canvasImg');
  var cropper = getCropper(key);
  var canvas = cropper.handler.getCroppedCanvas({maxWidth:cropper.maxWidth, maxHeight:cropper.maxHeight}); 

  if(key == "profile") {
    var img = $img[0];
    var overlay = $img.prev()[0];
    var ctx = canvas.getContext('2d');
    ctx.drawImage(overlay, canvas.width / 2 - overlay.width / 2, canvas.height - overlay.height * 2, overlay.width, overlay.height);
  }

  var url = canvas.toDataURL('image/jpeg'); 

  $downloadBtn.attr('href', url);
  $img.attr('src', '');
  $croppedImg.attr('src', url);
});

$('[data-canvas-button="reset"]').on('click', function(e) {
  var $img = $(this).parent().parent().find('[data-canvas-img]');
  var $croppedImg = $(this).parent().parent().find('[data-canvas-img-cropped]');
  var $downloadBtn = $(this).parents('.canvas-column').next().find('[data-canvas-button="download"]');

  $(this).addClass('hidden'); 
  $(this).prev().removeClass('hidden'); 
  $downloadBtn.addClass('hidden'); 
  $img.next('.cropper-container').removeClass('hidden'); 
  $img.parent().removeClass('is-cropped');

  var key = $img.data('canvasImg');
  var cropper = getCropper(key);
  var url = cropper.handler.getCroppedCanvas({maxWidth:cropper.maxWidth, maxHeight:cropper.maxHeight}).toDataURL('image/jpeg'); 

  $downloadBtn.attr('href', '');
  $img.attr('src', url);
  $croppedImg.attr('src', '');
  cropper.handler.reset();
});

$('[data-canvas-file]').on('change', function() {
  var $prev = $(this).parent().prev();
  var $toolbar = $prev.find('.toolbar');
  var $img = $prev.find('[data-canvas-img]');
  var $croppedImg = $prev.find('[data-canvas-img-cropped]');

  $prev.find('.canvas-wrapper').addClass('has-image');
  $toolbar.find('[data-canvas-button="reset"]').addClass('hidden'); 
  $toolbar.find('[data-canvas-button="crop"]').removeClass('hidden'); 

  var url = URL.createObjectURL(this.files[0]); 

  $img.attr('src', url); 
  $croppedImg.attr('src', '');

  processImage($img);
});

function processImage($img) {
  $img.next('.cropper-container').removeClass('hidden'); 
  var key = $img.data('canvasImg');
  getCropper(key).handler.replace($img[0].src); 
}

function getCropper(key) {
  return cropper[key];
}

function failed() {
  console.error("The provided file couldn't be loaded as an Image media");
}

var langFilter = '';
var typeFilter = '';

$('[data-select-guide-lang]').on('change', function() {
  var $option = $(this).children("option:selected");
  var titles = $option.data('titles');
  var downloads = $option.data('downloads');
  var purchases = $option.data('purchases');

  var $parent = $(this).parent('.controls');
  var $download = $parent.find('.button');
  var $purchaseLink = $parent.find('.purchase-link');

  var $titles = $('[data-select-guide-title]');
  $titles.empty();
  for(var i = 0; i < titles.length; i ++) {
    $titles.append(
      '<option data-download="'+downloads[i]+'" data-purchase="'+purchases[i]+'">'+titles[i]+'</option>'
    );
  }

  $download.attr('data-download', downloads[0]);
  if (purchases[0] == '') {
    $purchaseLink.hide();
  } else {
    $purchaseLink.show().attr('href', purchases[0]);
  }

  });

$('[data-select-guide-title]').on('change', function() {
  var $option = $(this).children("option:selected");
  var download = $option.data('download');
  var purchase = $option.data('purchase');

  var $parent = $(this).parent('.controls');
  var $download = $parent.find('.button');
  var $purchaseLink = $parent.find('.purchase-link');

  $download.attr('data-download', download);
  if (purchase == '') {
    $purchaseLink.hide();
  } else {
    $purchaseLink.show().attr('href', purchase);
  }
});

$('[data-select-lang]').on('change', function() {
  $('.no-results').remove();
  if ($('.example-systems').hasClass('active')){
    var parent = $('.systems-grid');
  } else if ($('.guides').hasClass('active')) {
    var parent = $('.reading-grid');
  }
  var $option = $(this).children("option:selected");
  var filterValue = $option.val();
  langFilter = filterValue;
  filterValue = filterValue + typeFilter;
  parent.find('.item:not('+filterValue+')').removeClass('active');
  parent.find('.item'+filterValue).addClass('active');

  var vis = parent.find('.item:visible');

  if (vis.length == 0) {
    parent.append(
      '<div class="no-results">There are no items matching these filters.</div>'
      );
  }
});

$('[data-select-type]').on('change', function() {
  $('.no-results').remove();
  if ($('.example-systems').hasClass('active')){
    var parent = $('.systems-grid');
  } else if ($('.guides').hasClass('active')) {
    var parent = $('.reading-grid');
  }
  var $option = $(this).children("option:selected");
  var filterValue = $option.val();
  typeFilter = filterValue;
  filterValue = filterValue + langFilter;
  parent.find('.item:not('+filterValue+')').removeClass('active');
  parent.find('.item'+filterValue).addClass('active');

  var vis = parent.find('.item:visible');

  if (vis.length == 0) {
    parent.append(
      '<div class="no-results">There are no items matching these filters.</div>'
      );
  }
});

$('[data-select-item-lang]').on('change', function() {
  var $option = $(this).children("option:selected");
  var index = $option.index();
  var download = $option.data('download');
  var purchase = $option.data('purchase');

  var $parent = $(this).parent('.item');
  var $currentImage = $parent.find('img.active');
  var $image = $parent.find('img').eq(index);
  var $download = $parent.find('.button');
  var $purchaseLink = $parent.find('.purchase-link');

  $currentImage.removeClass('active');
  $image.addClass('active');

  $download.attr('href', download);
  $purchaseLink.attr('href', purchase);
});

$('[data-src="#subscribe-form-modal"]').fancybox({
  afterShow: function(instance, slide) {
    var fileId = slide.opts.$orig.data('download');
    slide.$slide.find('form').data('download', fileId);
  },
  beforeClose: function(instance, slide) {
    var $form = slide.$slide.find('form');
    $form.data('download', '');
    $form.find('.form-messages').empty();
    $form.find('input').show();
    $form.find('button').show();
  }
});

$('.subscribe-form').on('submit', function(e) {
  e.preventDefault();
  var fileId = '';
  var $this = $(this);
  var $form = $this;
  var data = $form.serialize();
  var fileId = $form.data('download');

  var $button = $form.find('button[type="submit"]');
  var buttonHTML = $button.html();
  $button.html('<i class="fas fa-spinner fa-spin"></i>');
  axios({
    method: 'post',
    url: '',
    data: data
  })
  .then(function(response) {
    var data = response.data;
    var success = data.success;
    var message = data.message;

    if(success) {
      $form[0].reset();
      $form.find('input').hide();
      $form.find('button').hide();
      appendMessage(window.Craft.subscribeFormData.formSuccessMessage, success);
      if(fileId) {
        downloadFile(fileId);
      }
    } else {
      var detail = data.response.detail;
      appendMessage(detail, success);
    }

    $button.html(buttonHTML);
  })
  .catch(function(error) {
    console.log(error);
  });

  function appendMessage(message, success) {
    var $formMessages = $form.find('.form-messages');
    $formMessages.empty();
    var messageClass = success ? "success" : "error"; 
    $formMessages.append('<p class="form-'+messageClass+'">'+message+'</p>');
  }

  function downloadFile(fileId) {
    var fileUrl = "index.php?p=actions/s3securedownloads/download-proxy/get-file&amp;uid="+fileId;
    var $tempLink = $('<a href='+fileUrl+'></a>');
    $tempLink[0].click();
  }
});

$('[data-src="#story-form-modal"],[data-src="#system-form-modal"]').fancybox({
  beforeClose: function(instance, slide) {
    var $form = slide.$slide.find('form');
    $form.find('.ff-form-success').remove();
  }
});

var $form = $('.freeform');
var totalFileCount = 0;
var totalFileSize = 0;

$form.each(function(i, e) {
  var form = $(e)[0];

  form.addEventListener('freeform-ready', function(event) {
      var freeform = event.target.freeform;

      var $button = $(form).find('button[type="submit"]');
      var buttonHTML = $button.html();
      var $formMessages =  $(form).find('.form-messages');

      freeform.addOnSubmitCallback(function(formElement, options, isBackButtonPressed) {
        if (isBackButtonPressed) {
          return false;
        }
        $button.html('<i class="fas fa-spinner fa-spin"></i>');
        return true;
      });

      freeform.addOnSuccessfulAjaxSubmit(function(event, form, response) {
        totalFileCount = 0;
        totalFileSize = 0;
        $('.file-previews').empty();
        $('.field-error').empty();
      })

      freeform.addOnAfterAjaxSubmit(function(event, form, response) {
        $button.html(buttonHTML);
      });

      freeform.setOption('renderSuccess', function() {
          var $successMessage = $('<p class="ff-form-success">'+window.Craft.freeformData.formSuccessMessage+'</p>');
          $formMessages.empty();
          $formMessages.append($successMessage);
          form.reset();
      });

      freeform.setOption('renderFormErrors', function(errors) {
        var $errorMessage = $('<p class="ff-form-error">'+window.Craft.freeformData.formErrorMessage+'</p>');
        $formMessages.empty();
        $formMessages.append($errorMessage);
    });
  });
});

$('#form-input-file').on('change', function(e) {
  var $this = $(this);

  var files = e.target.files;
  var fileExtensions = getFileExtensions($this.data('file-kinds').split(","));
  var maxSize = Math.round(parseInt($this.data('max-size')) / 1000);
  var fileCount = $this.data('file-count');

  var $filePreviews = $this.siblings('.file-previews');
  var $fieldError = $this.siblings('.field-error');

  for(var i = 0; i < files.length; i ++) {
    var file = files[i];
    var fileName = file.name;
    var fileSize = Math.round(file.size/1000000 * 10) / 10;
    var fileExtension = fileName.split('.').pop().toLowerCase();

        if(files.length > fileCount - 1 || totalFileCount > fileCount - 1) {
      return setFieldError('You may only upload up to ' + fileCount + ' files');
    } 
    else if($.inArray(fileExtension, fileExtensions) == -1) {
      return setFieldError('Uploaded file type must be a ' + fileExtensions);
    } 
    else if(fileSize + totalFileSize > maxSize) {
      return setFieldError('Maximum upload size must be less than ' + maxSize + ' MB');
    } 
    else {
      totalFileCount ++;
      totalFileSize += fileSize;
      totalFileSize = Math.round(totalFileSize * 10 ) / 10;

      $filePreviews.append(
        '<div class="file-preview" data-file-size="'+fileSize+'">'+fileName+
          '<a class="file-preview-remove-btn"><i class="far fa-times-circle"></i></a>' +
        '</div>'
      );
    }

    removeFieldError();
  }

  function setFieldError(message) {
    $fieldError.html(message);
    $this.val("");
  }

  function removeFieldError() {
    $fieldError.empty();
  }

  function getFileExtensions(fileExtensions) {
    var extensions = [];
    for(var i = 0; i < fileExtensions.length; i ++) {
      var ext = fileExtensions[i];
      switch(ext) {
        case "image":
          extensions.push("jpg", "png", "bmp", "gif");
          break;
        case "word":
          extensions.push("doc", "docx");
          break;
        case "pdf":
          extensions.push("pdf");
          break;
      }
    }
    return extensions;
  }
});

$(document).on('click', '.file-preview-remove-btn', function(e) {
  e.preventDefault();
  var $this = $(this);

  var fileSize =  Math.round(parseFloat($this.parent().data('file-size')) * 10) / 10;
  totalFileCount --;
  totalFileSize -= fileSize;
  totalFileSize = Math.round(totalFileSize * 10 ) / 10;

  $this.parent().remove();
  if(isEmpty($('.file-previews'))) {
    $('.field-error').empty();
  }

  function isEmpty(el) {
    return !$.trim(el.html());
  }
});

$('.canvas-thumb').on('click', function(e) {
  e.preventDefault();
  $this = $(this);
  $this.siblings().removeClass('active');
  $this.addClass('active');
  var src = $this.find('img').attr('src');
  $this.parents('.header-frame').find('.header-canvas').find('img').attr('src', src);
  $this.parents('.header-frame').find('.button').attr('href', src);
  $this.parents('.header-frame').find('.button').attr('download', src.split('/')[2]);  
});


$(function() {
  $('.menu-right-item').each(function() {
    if (!$(this).hasClass('social-block') && $(this).find('a').attr('target') == '_blank') {
      $(this).find('a').append('<i class="fas fa-external-link-alt"></i>');
    }
  });

  $('.footer-block').each(function() {
    link = $(this).find('a');
    if (link.attr('target') == '_blank') {
      link.append('<i class="fas fa-external-link-alt"></i>');
    }
  });

  $('a.button.rd').each(function() {
    if ($(this).attr('target') == '_blank') {
      $(this).append('<i class="fas fa-external-link-alt"></i>');
    }
  })
});

function initForms() {
  $('.subscribe-form, .freeform').each(function(i, e) {
    updateCSRFToken($(e));
  });
}

function updateCSRFToken($form) {
  axios({
      method: 'get',
      url: '/actions/site-module/site/get-csrf',
    })
    .then(function(response) {
      $form.find('input[name="CRAFT_CSRF_TOKEN"]').val(response.data.value);
    })
    .catch(function(error) {
      console.log(error);
    });
}

initForms();